<template>
  <BModal
    id="modalConfirmation"
    ref="modalConfirmation"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    :centered="true"
    style="width: 600px;"
  >
    <div class="text-center my-1">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        class="m-auto"
      >
      <div class="font-semibold text-2xl text-black my-2">
        Batal Menambahkan Working Space?
      </div>
      <p class="text-black">
        Jika anda menekan kembali maka data yang anda input akan tidak tersimpan. Yakin Ingin membatalkan?
      </p>
      <div class="flex justify-center">
        <BButton
          size="md"
          variant="outline-primary"
          class="mr-1"
          style="width: 160.5px"
          @click="$bvModal.hide('modalConfirmation')"
        >
          Tidak
        </BButton>
        <BButton
          size="md"
          variant="primary"
          style="width: 160.5px"
          block
          @click.prevent="$router.back()"
        >
          Iya
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
export default {
  name: 'ModalDelete',
}
</script>
